import * as React from "react"
import {
  Heading,
  Text,
  Badge,
  Spacer,
  AnchorButton,
  EmptyState,
  ThemeProvider,
} from "gatsby-interface"
import { graphql, PageProps } from "gatsby"
import formatDistance from "date-fns/formatDistance"
import { releaseNotes as releaseNotesText } from "../locales/default.js"
import Footer from "../components/footer"
import Header from "../components/header"
import {
  ReleaseNotesLayout,
  ReleaseNotesItem,
  ReleaseNoteDate,
  ReleaseNoteContent,
  ReleaseNoteHeader,
  releaseNoteTitleCss,
  releaseNoteHtmlCss,
} from "../components/release-notes/ReleaseNotesLayout"
import { MdArrowForward } from "react-icons/md"

type AnnouncementType = "NEW_FEATURE" | "MAINTENANCE" | "BUGFIX"

const getTone = (type: AnnouncementType) =>
  type === "BUGFIX" ? "WARNING" : type === "MAINTENANCE" ? "NEUTRAL" : "SUCCESS"

const getLabel = (type: AnnouncementType) =>
  type === "BUGFIX"
    ? releaseNotesText.labels.bugfix.toUpperCase()
    : type === "MAINTENANCE"
    ? releaseNotesText.labels.maintenance.toUpperCase()
    : releaseNotesText.labels.newFeature.toUpperCase()

// eslint-disable-next-line
export type ReleaseNotesPageProps = PageProps<any>
type Note = {
  cloud_release_note_group: {
    noteType: AnnouncementType
    link?: string
  }
  date: string
  title: string
  content: string
}

export default function ReleaseNotesPage({ data }: ReleaseNotesPageProps) {
  const { allWpCloudReleaseNote } = data
  const notes = (allWpCloudReleaseNote?.nodes || []) as Note[]

  return (
    <ThemeProvider>
      <main>
        <Header isAbsolute={false} />
        <ReleaseNotesLayout>
          <Spacer size={7} />
          <Heading as="h1">{releaseNotesText.headers.title}</Heading>
          <Spacer size={7} />

          {notes.length === 0 ? (
            <EmptyState
              variant="BORDERED"
              heading={releaseNotesText.headers.emptyState}
              headingAs="h2"
              text={releaseNotesText.messages.stayTuned}
            />
          ) : (
            notes.map((note: Note, index: number) => (
              <ReleaseNotesItem key={`${note.title}-${index}`}>
                <ReleaseNoteDate>
                  <Text size="S" css={{ margin: 0, padding: 0 }}>
                    {formatDistance(new Date(note.date), new Date())}
                  </Text>
                </ReleaseNoteDate>

                <ReleaseNoteContent>
                  <ReleaseNoteHeader>
                    <Heading as="h2" css={releaseNoteTitleCss}>
                      {note.title}
                    </Heading>

                    <Badge
                      size="S"
                      tone={getTone(note.cloud_release_note_group.noteType)}
                    >
                      {getLabel(note.cloud_release_note_group.noteType)}
                    </Badge>
                  </ReleaseNoteHeader>

                  <Spacer size={4} />

                  <div
                    dangerouslySetInnerHTML={{ __html: note?.content }}
                    css={releaseNoteHtmlCss}
                  />

                  {note?.cloud_release_note_group?.link && (
                    <>
                      <AnchorButton
                        size="S"
                        href={note?.cloud_release_note_group?.link}
                        rightIcon={<MdArrowForward />}
                      >
                        {releaseNotesText.actions.viewDetails}
                      </AnchorButton>
                    </>
                  )}
                </ReleaseNoteContent>
              </ReleaseNotesItem>
            ))
          )}
        </ReleaseNotesLayout>
      </main>
      <Footer />
    </ThemeProvider>
  )
}

export const pageQuery = graphql`
  query ReleaseNotesQuery {
    allWpCloudReleaseNote {
      nodes {
        cloud_release_note_group {
          noteType
          link
        }
        date
        title
        content
      }
    }
  }
`
